import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {ElLoading} from 'element-plus';

let loadingInstance: any;

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/layout/DefaultLayout.vue'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/views/IndexView.vue'),
            },
            {
                path: '/resources',
                name: 'resources',
                component: () => import('@/views/Resource.vue'),
            },
            {
                path: '/videos',
                name: 'teamBuy',
                component: () => import('@/views/TeamBuy.vue'),
            },
            {
                path: '/guide',
                name: 'guide',
                component: () => import('@/views/Guide.vue'),
            },
            {
                path: '/uCenter',
                name: 'uCenter',
                component: () => import('@/views/UCenter.vue'),
            },
            {
                path: '/confirmOrder',
                name: 'confirmOrder',
                component: () => import('@/views/ConfirmOrder.vue'),
            },
            {
                path: '/order',
                name: 'order',
                component: () => import('@/views/Order.vue'),
            },
            {
                path: '/country',
                name: 'country',
                component: () => import('@/views/Country.vue'),
            },
            {
                path: '/platform',
                name: 'platform',
                component: () => import('@/views/Platform.vue'),
            },
            {
                path: '/setting',
                name: 'setting',
                component: () => import('@/views/Setting.vue'),
            },
            {
                path: '/channel',
                name: 'channel',
                component: () => import('@/views/Channel.vue'),
            },
            {
                path: '/myChannel',
                name: 'myChannel',
                component: () => import('@/views/MyChannel.vue'),
            },
            {
                path: '/channelApply',
                name: 'channelApply',
                component: () => import('@/views/ChannelApply.vue'),
            },
            {
                path: '/issued',
                name: 'issued',
                component: () => import('@/views/Issued.vue'),
            },
            {
                path: '/myResource',
                name: 'myResource',
                component: () => import('@/views/MyResource.vue'),
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('@/views/Users.vue'),
            },
            {
                path: '/recode',
                name: 'recode',
                component: () => import('@/views/Recode.vue'),
            },
            {
                path: '/bannerSet',
                name: 'bannerSet',
                component: () => import('@/views/BannerSet.vue'),
            },
            {
                path: '/qrcode',
                name: 'qrcode',
                component: () => import('@/views/Qrcode.vue'),
            },
            {
                path: '/guideSet',
                name: 'guideSet',
                component: () => import('@/views/GuideSet.vue'),
            },
            {
                path: '/video',
                name: 'groupBuy',
                component: () => import('@/views/GroupBuy.vue'),
            },
            {
                path: '/issuedSet',
                name: 'issuedSet',
                component: () => import('@/views/IssuedSet.vue'),
            },
            {
                path: '/orderTipSet',
                name: 'orderTipSet',
                component: () => import('@/views/OrderTipSet.vue'),
            },
            {
                path: '/myOrder',
                name: 'myOrder',
                component: () => import('@/views/MyOrder.vue'),
            },
            {
                path: '/teamConfirmOrder',
                name: 'teamConfirmOrder',
                component: () => import('@/views/TeamConfirmOrder.vue'),
            },
            {
                path: '/myWithdraw',
                name: 'myWithdraw',
                component: () => import('@/views/MyWithdraw.vue'),
            },
            {
                path: '/withdraw',
                name: 'withdraw',
                component: () => import('@/views/Withdraw.vue'),
            },
            {
                path: '/teamOrder',
                name: 'teamOrder',
                component: () => import('@/views/TeamOrder.vue'),
            },
            {
                path: '/issuedApply',
                name: 'issuedApply',
                component: () => import('@/views/IssuedApply.vue'),
            },
            {
                path: '/adminOrder',
                name: 'adminOrder',
                component: () => import('@/views/AdminOrder.vue'),
            },
            {
                path: '/userIssued',
                name: 'userIssued',
                component: () => import('@/views/UserIssued.vue'),
            },
            {
                path: '/VipSet',
                name: 'vipSet',
                component: () => import('@/views/VipSet.vue'),
            },
            {
                path: '/RechargeSet',
                name: 'rechargeSet',
                component: () => import('@/views/RechargeSet.vue'),
            },
            {
                path: '/VipBuy',
                name: 'vipBuy',
                component: () => import('@/views/VipBuy.vue'),
            },
            {
                path: '/RechargeBuy',
                name: 'rechargeBuy',
                component: () => import('@/views/RechargeBuy.vue'),
            },
            {
                path: '/RechargeBuy',
                name: 'rechargeBuy',
                component: () => import('@/views/RechargeBuy.vue'),
            },
            {
                path: '/pay/result',
                name: 'payResult',
                component: () => import('@/views/PayResult.vue'),
            },
            {
                path: '/moWxPay',
                name: 'moWxPay',
                component: () => import('@/views/MoWxPay.vue'),
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register.vue'),
    },
    {
        path: '/forget',
        name: 'forget',
        component: () => import('@/views/Forget.vue'),
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (loadingInstance && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
    loadingInstance = ElLoading.service({
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0.7)',
    });
    next();
})

router.afterEach((to, from) => {
    if (loadingInstance && loadingInstance.hasOwnProperty('close') && typeof loadingInstance.close === "function") {
        loadingInstance.close();
        loadingInstance = null;
    }
})
export default router
