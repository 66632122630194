import {createStore} from 'vuex'
import {objectData} from "@/lib/interface";

export default createStore({
    state: {
        resType: false,
        resFunc: null,
        fileType: '',
        selectTitle: '选择文件',
        userInfo: {
            nick_name: '',
            user_image: ''
        },
        isVip: 0,
        wxPay: {
            open: false,
            config: null
        }
    },
    getters: {},
    mutations: {
        doWxPay(state: any, config: objectData | null) {
            if(config == null){
                state.wxPay.config = null;
                state.wxPay.open = false;
            }else{
                state.wxPay.config = config;
                state.wxPay.open = true;
            }
        },
        changeIsVip(state:any, value:objectData) {
            state.isVip = value;
        },
        changeUserInfo(state:any, value:objectData) {
          state.userInfo = value;
        },
        openRes(state: any, type: Boolean) {
            state.resType = type;
        },
        doOpenRes(state: any, config: objectData) {
            if (config.hasOwnProperty('type')) {
                state.resType = config.type;
                if (config.hasOwnProperty('func') && typeof config.func === "function") {
                    state.resFunc = config.func;
                } else {
                    state.resFunc = null;
                }
                if (config.hasOwnProperty('fileType')) {
                    state.fileType = config.fileType;
                } else {
                    state.fileType = '';
                }
                if (config.hasOwnProperty('selectTitle')) {
                    state.selectTitle = config.selectTitle;
                } else {
                    state.selectTitle = '选择文件';
                }
            }
        },
    },
    actions: {},
    modules: {}
})
